<template>
  <!-- 暗色主题 -->
  <button class="w-full flex justify-center items-center pb-3 sm:w-auto sm:p-3" @click="changeTheme" v-if="!isDark">
    <svg
      t="1678611862446"
      class="fill-current"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="5106"
      width="16"
      height="16"
    >
      <path
        d="M427.989333 181.12A395.84 395.84 0 0 0 426.666667 213.333333c0 211.754667 172.245333 384 384 384 10.816 0 21.557333-0.437333 32.213333-1.322666C805.344 743.733333 671.232 853.333333 512 853.333333c-188.213333 0-341.333333-153.12-341.333333-341.333333 0-159.232 109.6-293.344 257.322666-330.88M512 85.333333C276.362667 85.333333 85.333333 276.362667 85.333333 512c0 235.648 191.029333 426.666667 426.666667 426.666667 235.648 0 426.666667-191.018667 426.666667-426.666667 0-9.525333-0.426667-18.933333-1.045334-28.309333A297.418667 297.418667 0 0 1 810.666667 512c-164.949333 0-298.666667-133.717333-298.666667-298.666667 0-45.408 10.186667-88.426667 28.309333-126.954666A424.672 424.672 0 0 0 512 85.333333z"
        p-id="5107"
      ></path>
    </svg>
  </button>
  <!-- 亮色主题 -->
  <button class="w-full flex justify-center items-center pb-3 sm:w-auto sm:p-3" @click="changeTheme" v-else>
    <svg
      t="1678611791440"
      class="fill-current"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4808"
      width="16"
      height="16"
    >
      <path
        d="M554.666667 42.666667v85.333333a42.666667 42.666667 0 0 1-85.333334 0V42.666667a42.666667 42.666667 0 0 1 85.333334 0z m-42.666667 810.666666a42.666667 42.666667 0 0 0-42.666667 42.666667v85.333333a42.666667 42.666667 0 0 0 85.333334 0v-85.333333a42.666667 42.666667 0 0 0-42.666667-42.666667zM149.962667 149.962667a42.666667 42.666667 0 0 0 0 60.341333l60.341333 60.341333a42.666667 42.666667 0 0 0 60.341333-60.341333l-60.341333-60.341333a42.666667 42.666667 0 0 0-60.341333 0z m603.392 603.392a42.666667 42.666667 0 0 0 0 60.330666v0.010667l60.341333 60.341333a42.666667 42.666667 0 0 0 60.341333-60.341333l-60.341333-60.341333a42.656 42.656 0 0 0-60.341333 0zM0 512a42.666667 42.666667 0 0 0 42.666667 42.666667h85.333333a42.666667 42.666667 0 0 0 0-85.333334H42.666667a42.666667 42.666667 0 0 0-42.666667 42.666667z m853.333333 0a42.666667 42.666667 0 0 0 42.666667 42.666667h85.333333a42.666667 42.666667 0 0 0 0-85.333334h-85.333333a42.666667 42.666667 0 0 0-42.666667 42.666667zM149.962667 874.037333a42.666667 42.666667 0 0 0 60.341333 0l60.330667-60.341333a42.666667 42.666667 0 0 0-60.330667-60.341333l-60.341333 60.341333a42.666667 42.666667 0 0 0 0 60.341333z m603.392-603.392a42.666667 42.666667 0 0 0 60.330666 0h0.010667l60.341333-60.341333a42.666667 42.666667 0 0 0-60.341333-60.341333l-60.341333 60.341333a42.656 42.656 0 0 0 0 60.341333zM768 512c0 141.386667-114.613333 256-256 256S256 653.386667 256 512s114.613333-256 256-256 256 114.613333 256 256z m-85.333333 0c0-94.261333-76.405333-170.666667-170.666667-170.666667s-170.666667 76.405333-170.666667 170.666667 76.405333 170.666667 170.666667 170.666667 170.666667-76.405333 170.666667-170.666667z"
        p-id="4809"
      ></path>
    </svg>
  </button>
</template>

<script>
export default {
  name: "Theme",
  data() {
    return {
      isDark: false,
    };
  },
  methods: {
    changeTheme() {
      this.isDark = !this.isDark;
      if (!this.$store.state.flavour) {
        this.$store.commit("changeFlavour", "ctp-frappe");
      } else {
        this.$store.commit("changeFlavour", "");
      }
    },
  },
};
</script>
