<template>
  <main class="flex flex-grow justify-center items-start mt-12 bg-ctp-base">
    <router-view></router-view>
  </main>
</template>

<script>
export default {
  name: "Main",
};
</script>
