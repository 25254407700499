<template>
  <footer class="py-3 bg-ctp-base">
    <Copyright></Copyright>
  </footer>
</template>

<script>
import Copyright from "@/components/common/Copyright";

export default {
  name: "Footer",
  components: {
    Copyright,
  },
};
</script>
